.profile-card-2 {
  max-width: 180px;
  height: 100px;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  background-position: center;
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  border-radius: 10px;
}
.profile-card-2 .profile-username {
  position: absolute;
  bottom: 50px;
  left: 30px;
  color: #ffffff;
  font-size: 17px;
  transition: all linear 0.25s;
}
@media screen and (max-width: 900px) and (orientation: landscape) {
  .profile-card-2 {
    max-width: 100%;
    height: 120px;
    background-color: #fff;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    background-position: center;
    overflow: hidden;
    position: relative;
    margin: 0;
    cursor: pointer;
    border-radius: 10px;
  }

  .profile-card-2 .profile-username {
    position: absolute;
    bottom: 50px;
    left: 30px;
    color: #fff;
    font-size: 25px;
    transition: all linear 0.25s;
  }
}
@media screen and (max-width: 900px) {
  .profile-card-2 {
    max-width: 100%;
    height: 120px;
    background-color: #fff;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    background-position: center;
    overflow: hidden;
    position: relative;
    margin: 0;
    cursor: pointer;
    border-radius: 10px;
  }
  .profile-card-2 .profile-username {
    position: absolute;
    bottom: 50px;
    left: 30px;
    color: #fff;
    font-size: 25px;
    transition: all linear 0.25s;
  }
}
.profile-card-2 img {
  transition: all linear 0.25s;
  
}
@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
  .profile-card-2 img {
      transition: all linear 0.25s;
      max-height: 100px;
      width: 100%;
    }
}


.profile-card-2 .profile-name {
  position: absolute;
  left: 30px;
  bottom: 70px;
  font-size: 30px;
  color: #fff;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  transition: all linear 0.25s;
}

.profile-card-2 .profile-icons {
  position: absolute;
  bottom: 30px;
  right: 30px;
  color: #fff;
  transition: all linear 0.25s;
}

.profile-card-2 .profile-icons .fa {
  margin: 5px;
}

.profile-card-2:hover img {
  filter: grayscale(100%);
  color: #f24b20;
}

.profile-card-2:hover .profile-name {
  bottom: 80px;
  color: #f24b20;
}

.profile-card-2:hover .profile-username {
  bottom: 60px;
  color: #f24b20;
}

.profile-card-2:hover .profile-icons {
  right: 40px;
}
