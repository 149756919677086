@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");
.font-link,
link:link,
link:visited,
link:hover {
  font-family: "Cormorant Garamond", serif;
  font-size: 50px;
  color: black;
}

.articlesImages img {
  width: 100%;
  height: 60%;
}

.homeImage img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.penPublicImage {
  width: 3%;
  height: 3%;
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .aboutMeImages img {
    width: 100%;
    margin-top: 36%;
  }

  .aboutImages {
    width: 8%;
  }

  .penAboutImage {
    width: 12%;
  }
  .penPublicImage {
    height: 5%;
    width: 5%;
  }

  .documentImages {
    display: none;
  }
  .headTextAbout,
  .headTextAbout span {
    font-family: "Sacramento", cursive;
    font-size: 200%;
    padding-left: 2%;
  }
  .icon-linkedin {
    width: 3.5%;
    margin-left: 2%;
  }
}

@media screen and (max-width: 768px) {
  .aboutMeImages img {
    width: 100%;
  }

  .aboutImages {
    width: 8%;
  }

  .penAboutImage {
    width: 12%;
  }
  .penPublicImage {
    height: 10%;
    width: 10%;
  }

  .documentImages {
    display: none;
  }
  .headTextAbout,
  .headTextAbout span {
    font-family: "Sacramento", cursive;
    font-size: 200%;
    padding-left: 2%;
  }
  .icon-linkedin {
    width: 7.5%;
    margin-left: 2%;
  }
}

@media (min-width: 1200px) {
  .headTextAbout,
  .headTextAbout span {
    display: none;
  }

  .aboutMeImages img {
    width: 100%;
    padding-top: 16%;
  }
  .icon-linkedin {
    width: 3.5%;
    margin-left: 2%;
  }
}

.text-description {
  font-size: 16px;
  margin-left: 9px;
}

.text-publisher {
  margin-top: -20px;
  font-size: 16px;
  margin-left: 9px;
  font-style: italic;
  margin-bottom: 9px;
}

.text-title {
  font-weight: bolder;
  margin-top: 11px;
}

.aboutMeImages img {
  width: 100%;
}

.aboutImages {
  width: 5%;
}

.penAboutImage {
  width: 8%;
}

.documentImages {
  width: 5%;
}

@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");

.headText,
.headText span {
  font-family: "Sacramento", cursive;
  font-size: 200%;
}

.homeHeadText {
  font-family: "Sacramento", cursive;
  font-size: 200%;
  margin-top: 4%;
  text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=Belleza&display=swap");
.commonText {
  font-family: "Belleza", sans-serif;
  color: black;
}

.commonText p {
  font-family: "Belleza", sans-serif;
  color: black;
}
/* Colabs */
@import url("https://fonts.googleapis.com/css?family=Seaweed+Script");
.plate {
  width: 410px;
  margin: 10% auto;
}

.script {
  font-family: "Seaweed Script" !important;
  color: #fff;
  text-align: center;
  font-size: 40px;
  position: relative;
  margin: 0;
}
.script span {
  /* background-color: #222; */
  padding: 0 0.3em;
}
.script:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  width: 100%;
  border-bottom: 3px solid rgb(12, 7, 7);
}

/* links */
a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
}

a:hover {
  color: var(--color-link-hover);
  outline: none;
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
  outline: none;
  background: lightgrey;
}

a:focus:not(:focus-visible) {
  background: transparent;
}

a:focus-visible {
  outline: 2px solid red;
  background: transparent;
}

.link {
  cursor: pointer;
  font-size: 18px;
  position: relative;
  white-space: nowrap;
  color: var(--color-text);
}

.link-icon {
  font-size: 50px;
}

.link::before,
.link::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
}

.link::before {
  content: "";
  /* show by default */
}

.link--thebe-jump {
  color: #b77721;
}
.link--thebe-jump:hover {
  color: #b77721;
  outline: none;
}
.link--thebe::before {
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
}

.link--thebe:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 2, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.2, 1);
}

.link--thebe::after {
  content: "";
  top: calc(100% + 4px);
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.4s 0.1s cubic-bezier(0.2, 1, 0.8, 1);
}

.link--thebe:hover::after {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.2, 1);
}
